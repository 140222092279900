var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading && _vm.ishow),expression:"loading && ishow"}],staticClass:"footerBanner"},[_c('div',{staticClass:"footerBox"},[_c('img',{staticClass:"img",attrs:{"src":_vm.$utils.getPng(_vm.domSrc)},on:{"load":_vm.handleImageLoad,"click":function($event){return _vm.toLink()}}}),(_vm.loading && _vm.type == 2)?_c('div',{class:{
        downNum: true,
        downOne: _vm.digitCount == 1,
        downTwo: _vm.digitCount == 2,
        downThree: _vm.digitCount >= 3,
      }},[_vm._v(" "+_vm._s(_vm.downDayCount)+" ")]):_vm._e(),(_vm.loading && _vm.type == 3)?_c('div',{class:{
        expire: true,
        expireFour: _vm.dayCount >= 4,
      }},[_vm._v(" "+_vm._s(_vm.day)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }