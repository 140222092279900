<template>
  <div class="footerBanner" v-show="loading && ishow">
    <div class="footerBox">
      <img
        @load="handleImageLoad"
        @click="toLink()"
        class="img"
        :src="$utils.getPng(domSrc)"
      />
      <!-- 不限时长会员用了多少次特权 -->
      <div
        :class="{
          downNum: true,
          downOne: digitCount == 1,
          downTwo: digitCount == 2,
          downThree: digitCount >= 3,
        }"
        v-if="loading && type == 2"
      >
        {{ downDayCount }}
      </div>
      <!-- 会员已过期多少天 -->
      <div
        :class="{
          expire: true,
          expireFour: dayCount >= 4,
        }"
        v-if="loading && type == 3"
      >
        {{ day }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterBanner',
  props: {
    type: {
      type: String,
      default: '6', //0-未登录、1-未开通过会员、2-不限时长会员、3-会员已过期、4-会员即将过期、5-会员、6-不展示
    },
    downDayCount: {
      type: String,
      default: '0',
    },
    day: {
      type: String,
      default: '0',
    },
    ishow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {},
  computed: {
    domSrc() {
      switch (this.type) {
        case '0':
          return 'icon-down-footerZero'
        case '1':
          return 'icon-down-footerOne'
        case '2':
          if (this.digitCount == 1) {
            return 'icon-down-footerTwo-one'
          } else if (this.digitCount == 2) {
            return 'icon-down-footerTwo-two'
          } else if (this.digitCount >= 3) {
            return 'icon-down-footerTwo-three'
          }
        case '3':
          if (this.dayCount == 1) {
            return 'icon-down-footerThree-one'
          } else if (this.dayCount == 2) {
            return 'icon-down-footerThree-two'
          } else if (this.dayCount == 3) {
            return 'icon-down-footerThree-three'
          } else if (this.dayCount >= 4) {
            return 'icon-down-footerThree-four'
          }
        case '4':
          return 'icon-down-footerFour'
        case '5':
          return 'icon-down-footerFive'
        default:
        // return 'icon-down-footerOne'
      }
    },
    digitCount() {
      return this.downDayCount.toString().length
    },
    dayCount() {
      return this.day.toString().length
    },
  },
  methods: {
    handleImageLoad() {
      this.loading = true
      this.$emit('loaded') // 通知父组件图片已加载
    },
    getHeight() {
      return this.$el.clientHeight
    },
    toLink() {
      if (this.type == '0') {
        let path = this.$route.fullPath.toString().slice(1)
        const lang = {
          en: '/en-US',
          hk: '/zh-HK',
          cn: '',
        }
        const routeData = this.$router.resolve({
          path: lang[this.$store.state.language] + '/login',
          query: {
            tab: 2,
            path,
          },
        })
        window.open(routeData.href, '_blank')
      } else {
        window.open('/vip', '_blank')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
